<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    textContent() {
      return this.block.fields.Paragraph;
    },
    heading() {
      return this.block.fields.HeadingLevel?.value || 'h4';
    },
  },
};
</script>

<template>
  <div class="title-paragraph__container">
  <component :is="heading" v-if="textContent" class="title-paragraph__text">
    {{textContent}}
  </component>
  </div>
</template>

<style>
  .title-paragraph__container {
    padding: 20px 10px;
    max-width: var(--site-max-width);
    margin: auto;
  }

  .title-paragraph__text {
    margin: 0;
    max-width: 750px;
    white-space: pre-wrap;
  }

  @media (--tabletAndDesktop) {
    .title-paragraph__container {
      padding-left: min(10vw, 115px);
    }
  }
</style>
